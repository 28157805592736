<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 11 18"
    class="arrow-icon"
    :class="['icon', direction]"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g
        :fill="color"
        fill-rule="nonzero"
      >
        <!-- v-if="size === 'sm'" -->
        <!-- <path
          d="M74.409 379.78c-.126 0-.251-.048-.347-.144l-3.01-3.017c-.191-.192-.191-.503 0-.695.192-.192.502-.192.693 0l2.664 2.67 2.663-2.67c.191-.192.502-.192.693 0 .192.192.192.503 0 .695l-3.01 3.017c-.096.096-.221.144-.346.144z"
          transform="translate(-72 -374) scale(-1 1) rotate(-90 0 452.189)"
        /> -->
        <path
          d="M30.564 170.623c-.304 0-.609-.116-.841-.348l-7.309-7.309c-.464-.465-.464-1.219 0-1.683.465-.465 1.219-.465 1.684 0l6.466 6.467 6.467-6.467c.465-.465 1.218-.465 1.683 0 .465.465.465 1.218 0 1.683l-7.308 7.309c-.233.232-.537.348-.842.348z"
          transform="translate(-25 -157) scale(-1 1) rotate(-90 0 196.343)"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
const colors: Record<string, string> = {
  dark: '#121212',
  transparent: 'transparent',
  semidark: '#787878',
  default: '#ABABAB',
  light: '#CDCDCD',
}

export default defineNuxtComponent({
  props: {
    direction: {
      type: String,
      validator: (prop: string) => ['top', 'bottom', 'right', 'left'].includes(prop),
      default: 'left',
    },
    variant: {
      type: String,
      validator: (value: string): boolean => Object.keys(colors).includes(value),
      default: 'default',
    },
    size: {
      type: Number,
      default: 12,
    },
  },
  setup(props) {
    const color = computed(() => colors[props.variant])

    const width = props.size
    const height = props.size * (11 / 18)

    return {
      color,
      width,
      height,
    }
  },
})
</script>

<style lang="scss">
.arrow-icon {
  transition-property: transform;
  transition-duration: .25s;
}

// .left {
//   // NOP
// }

.right {
  @apply rotate-180;
}

.top {
  @apply rotate-90;
}

.bottom {
  @apply -rotate-90;
}
</style>
